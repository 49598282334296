@import "tailwindcss/base";
/* @import "./custom-base-styles.css"; */

@import "tailwindcss/components";
@import "./components.css";

@import "tailwindcss/utilities";
@import "./utils.css";

* {
  box-sizing: border-box;
}


@font-face {
  font-family: "Night";
  src: url('../assets/fonts/NightWindSent/NightWindSentSample.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Night";
  src: url('../assets/fonts/NightWindSent/NightWindSentSample.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Night";
  src: url('../assets/fonts/NightWindSent/NightWindSentSample.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

.swiper-pagination-bullet {
  border: 1px solid #27C065 !important;
  border-radius: 3px !important; 
  width: 14px !important;
  height: 14px !important;
  background-color: white !important;
  opacity: 1 !important;
  transition: all;
}

.swiper-pagination-bullet-active {
  background-color: #27C065 !important;
  border-radius: 3px !important; 
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -4px !important;
}

.mySwiper2 .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 74px !important;
}

@media (max-width: 640px) {
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 60px !important;
  }
  .mySwiper2 .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 54px !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  background-color: theme('colors.w-green.100');
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: theme('colors.w-green.200'); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #58eb92; 
}

.text-outline {
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}

.text-outline-2 {
  text-shadow: 0 0 0.5px black, 0 0 0.5px black, 0 0 0.5px black, 0 0 0.5px black;
}


.list-section li {
  list-style: none;
  position: relative;
}

.list-section li::before {
  content: '•';
  position: absolute;
  left: -20px;
}