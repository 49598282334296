.nav-active {
  font-weight: 500;
  color: theme('colors.w-green.200');
  border-bottom: 4px solid theme('colors.w-green.200');
}

.nav-shadow{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.nav-hr {
  border: 0;
  border-bottom: 1px solid #384634;
}

.nav-side-active {
  font-weight: 700;
  color: theme('colors.w-green.200');
}